// Licensed Materials - Property of IBM
// (C) Copyright IBM Corporation 2018, 2023
// US Government Users Restricted Rights - Use, duplication or disclosure
// restricted by GSA ADP Schedule Contract with IBM Corp.

/* global __webpack_public_path__:true */
import './public-path'

async function boot() {
  /* In order to provide translation anywhere/anytime, setIntl must be called before importing app components*/
  // In order to support the React Module Federation, we need to delay the loading react-intl inside
  // boot function (instead of using the import at top of this file) and then call setIntl after
  // the react-intl module is loaded.
  await import('Uif/lib/i18n/react-intl').then(loadedModule => {
    loadedModule.setIntl()
  })

  /* Load and execute whatever is needed before rendering the app*/

  /* Import BaseApp and start rendering */
  const trialImport = await import(
    /* webpackMode: "lazy", webpackChunkName: "trialApp" */
    './Trial'
  )
  const renderAppImport = await import(
    /* webpackMode: "lazy", webpackChunkName: "commonEntry" */
    'Apps/commonEntry'
  )

  const trial = trialImport.default
  const renderApp = renderAppImport.default
  renderApp(trial)
}
boot().catch(console.error)
